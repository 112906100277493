<template>
  <b-card title="">
    <b-tabs>
      <b-tab @click="$router.push('/seo-classical-course')">
        <template
          #title
          active
        >
          <feather-icon icon="FolderIcon" />
          <span>Classical Course</span>
        </template>

      </b-tab>
      <b-tab @click="$router.push('/seo-online-course')">
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span>Online Course</span>
        </template>

        <!-- <SeoOnlineCourse></SeoOnlineCourse> -->
        <!-- <AddCategory></AddCategory> -->
      </b-tab>
      <b-tab @click="$router.push('/seo-course-in-city')">
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span> Course In City</span>
        </template>

        <!-- <SeoCourseIncity></SeoCourseIncity> -->
      </b-tab>

    </b-tabs>
    <router-view v-slot="{ Component, route }">
      <SeoClassicalCourse v-if="route.name === 'index-specific-price' || route.name === 'add-specific-price' " />
      <router-view v-else>
        <component :is="Component" />
      </router-view>
    </router-view>
  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BTabs, BTab, BCard, BCardText,
} from 'bootstrap-vue'
import SeoClassicalCourse from './SeoClassicalCourse.vue'
import SeoOnlineCourse from './SeoOnlineCourse.vue'
import SeoCourseIncity from './SeoCourseIncity.vue'
// import CategoriesList from './categoriesList.vue';
// import AddCategory from './AddCategory.vue';

export default {
  components: {
    BCardCode,
    BCard,
    BCardText,
    BTabs,
    BTab,
    SeoClassicalCourse,
    SeoOnlineCourse,
    SeoCourseIncity,
  },
  data() {
    return {

    }
  },
}
</script>
